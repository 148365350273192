import { defineStore } from "pinia";
import axios from "~/plugins/axios";
import Cookies from 'js-cookie'

const $axios = axios().provide.axios;

export const useUserStore = defineStore('user', {
    state: () => ({
        id: '',
        name: '',
        email: '',
        api_token: '',
        document: '',
        phone: '',
        zip_code: '',
        street: '',
        number: '',
        neighborhood: '',
        city: '',
        state: '',
        complement: '',
        isLoggedIn: false,
        psychologist: '',
        first_payment: '',
        termsAccepted: false
    }),
    
    actions: {
        async login(email, password) {
            await $axios.post('/api/auth/login', {
              email: email,
              password: password,
              login: email
            }).then((result) => {
                let data = this.parseJwt(result.data.access_token)

                const expirationInMilliseconds = result.data.expires_in * 1000
                const expirationDate = new Date(Date.now() + expirationInMilliseconds)
        
                Cookies.set('token', result.data.access_token, { expires:  expirationDate})
                Cookies.set('user', JSON.stringify(data), { expires:  expirationDate})

                this.$state.balance = data.balance
                this.$state.api_token = result.data.access_token
                this.$state.id = data.user_id
                this.$state.name = data.name
                this.$state.document = data.document
                this.$state.phone = data.phone
                this.$state.email = data.email
                this.$state.first_payment = data.first_payment
                this.$state.termsAccepted = data.terms_accepted
                this.$state.psychologist = data.psychologist
                this.$state.isLoggedIn = true;

            });
        },
        
        async register({name, email, phone, document, password, zip_code, number, street, neighborhood, city, state, complement}) {
            await $axios.post('/api/auth/register', {
              name: name,
              email: email,
              phone: phone,
              document: document,
              password: password,
              zip_code: zip_code,
              street: street,
              neighborhood: neighborhood,
              city: city,
              state: state,
              number: number,
              complement: complement

            }).then(async (result) => {
                await this.login(email, password);
            })
        },

        async getUser() {

            let res = Cookies.get('user')
            if(res){
                res = JSON.parse(res)
                this.$state.id = res.user_id
                this.$state.isLoggedIn = true
                this.$state.document = res.document

                $axios.post('/api/auth/me').then((result) => {
                    this.$state.name = result.data.name
                    
                    this.$state.phone = result.data.phone
                    this.$state.email = result.data.email
                    this.$state.psychologist = result.data.psychologist
                    this.$state.first_payment = result.data.first_payment
                    this.$state.termsAccepted = result.data.terms_accepted
                    this.$state.zip_code = result.data.zip_code
                    this.$state.street = result.data.street
                    this.$state.number = result.data.number
                    this.$state.neighborhood = result.data.neighborhood
                    this.$state.city = result.data.city
                    this.$state.state = result.data.state
                    this.$state.complement = result.data.complement

                });
            }
        },
        base64urlToBase64(base64url) {
            base64url = base64url.replace(/-/g, '+').replace(/_/g, '/');
        
            switch (base64url.length % 4) {
                case 2:
                    base64url += '==';
                    break;
                case 3:
                    base64url += '=';
                    break;
            }
        
            return base64url;
        },
        parseJwt (token) {
            try {
                return JSON.parse(atob(this.base64urlToBase64(token.split(".")[1])));
            } catch (e) {
                return null;
            }
        },
      
        async logout() {
            this.resetState()
        },

        resetState() {      
            Cookies.remove('token')
            Cookies.remove('user')
            this.$state.id = ''
            this.$state.name = ''
            this.$state.email = ''
            this.$state.api_token = ''
            this.$state.isLoggedIn = false
            this.$state.document = ''
            this.$state.phone = ''
            this.$state.psychologist = ''
            this.$state.first_payment = ''
            this.$state.termsAccepted = false

            return navigateTo('/')
        },
    },
    persist: true,
})